import React, { useState, useEffect } from 'react';
import './App.css';
import Confetti from 'react-confetti';
import Modal from 'react-modal';
import { Resend } from 'resend';
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://vihrwwybiygbgfbxfvhs.supabase.co'
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)
function App() {
    const slugs = ['will-an-ai-get-gold-on-any-internat', 'will-a-book-claimed-to-be-written-b-6a6dc28b047c', 'will-a-reliable-and-general-househo','in-2028-will-an-ai-be-able-to-gener']; 
    const [marketPositions, setMarketPositions] = useState({});
    const [marketWeights, setMarketWeights] = useState({});
    const [marketUrls, setMarketUrls] = useState({});
    const [email, setEmail] = useState('');
    const [threshold, setThreshold] = useState(60);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [newMarketUrl, setNewMarketUrl] = useState('');
    const colors = ['#007BFF', '#FFC107', '#28A745', '#DC3545', '#17A2B8', '#FF5733', '#C70039', '#900C3F', '#581845', '#FFD700', '#7FFF00', '#D2691E', '#8B008B', '#556B2F', '#FF8C00', '#ADFF2F', '#FF69B4', '#CD5C5C', '#4B0082', '#FFFF00'];

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                const responses = await Promise.all(slugs.map(slug => fetch(`https://api.manifold.markets/v0/slug/${slug}`)));
                const data = await Promise.all(responses.map(response => response.json()));
                // Process the data to update the state
                const newMarketPositions = {};
                const newMarketWeights = {};
                const newMarketUrls = {};
                data.forEach((marketData, index) => {
                    newMarketPositions[marketData.question] = marketData.probability;
                    newMarketWeights[marketData.question] = 25; // Initialize weights to 25
                    newMarketUrls[marketData.question] = marketData.url; // Store the url
                });
                setMarketPositions(newMarketPositions);
                setMarketWeights(newMarketWeights);
                setMarketUrls(newMarketUrls);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchMarketData();
    }, []);

    const handleAddMarket = async () => {
        const slug = newMarketUrl.split('/').pop();
        try {
            const response = await fetch(`https://api.manifold.markets/v0/slug/${slug}`);
            const marketData = await response.json();
            setMarketPositions(prevPositions => ({...prevPositions, [marketData.question]: marketData.probability}));
            setMarketWeights(prevWeights => ({...prevWeights, [marketData.question]: 25}));
            setMarketUrls(prevUrls => ({...prevUrls, [marketData.question]: marketData.url}));
            setModalIsOpen(false);
            setNewMarketUrl('');
        } catch (error) {
            console.error('Error adding market:', error);
            alert('The URL provided is probably wrong. Please check and try again.');
        }
    };

    const handleWeightChange = (question, newWeight) => {
        setMarketWeights(prevWeights => {
            const updatedWeights = {...prevWeights, [question]: parseInt(newWeight)};
            const totalWeight = Object.values(updatedWeights).reduce((a, b) => parseInt(a) + parseInt(b), 0);
            const weightedProbability = Object.entries(marketPositions).reduce((total, [question, probability]) => total + (probability * (updatedWeights[question] || 0)), 0) / totalWeight;
            if (weightedProbability * 100 < 100) {
                setThreshold((weightedProbability * 100) + 10);
            } else {
                setThreshold(100);
            }
            return updatedWeights;
        });
    };

    const handleRemoveMarket = (question) => {
        setMarketPositions(prevPositions => {
            const updatedPositions = {...prevPositions};
            delete updatedPositions[question];
            return updatedPositions;
        });
        setMarketWeights(prevWeights => {
            const updatedWeights = {...prevWeights};
            delete updatedWeights[question];
            return updatedWeights;
        });
        setMarketUrls(prevUrls => {
            const updatedUrls = {...prevUrls};
            delete updatedUrls[question];
            return updatedUrls;
        });
    };

    const handleThresholdChange = (newThreshold) => {
        setThreshold(newThreshold);
    };

    const handleEmailChange = (newEmail) => {
        setEmail(newEmail);
    };


    const setAlert = async () => {
      // Implement email sending functionality here
      const totalWeight = Object.values(marketWeights).reduce((a, b) => parseInt(a) + parseInt(b), 0);
      const weightedProbability = Object.entries(marketPositions).reduce((total, [question, probability]) => total + (probability * (marketWeights[question] || 0)), 0) / totalWeight;
      console.log(weightedProbability, threshold)
      if (weightedProbability*100 > threshold) {
        alert('Whoops, your threshold has already been breached, AGI is coming🚨🚨🚨')
      }
      else{
          if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            const markets = {
                markets: marketPositions,
                weights: marketWeights,
                threshold: threshold
            };
            const sentAlert = false;
            const { data, error } = await supabase
                .from('alertdb')
                .insert([{ email, sentAlert, markets }]);
            if (error) {
                console.error('Error adding subscription:', error);
                setEmail('');
                alert('Something went wrong');
            } else {
                console.log('Subscription added successfully:', data);
                setEmail('');
                alert('Alert added successfully!');
            }
        } else {
            alert('Please provide a valid email address');
        }
      }

  };
    async function sendEmailViaWorker(toAddress, emailBody) {
      const workerUrl = 'https://worker-jolly-dew-2d93.raghav-toshniwal.workers.dev'; // Replace with your actual worker URL
    
      try {
        const response = await fetch(workerUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            toAddress: toAddress,
            Body: emailBody
          }),
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const result = await response.json();
        console.log('Email sent successfully:', result);
        alert('Email sent successfully, check spam and mark us as not spam to not miss future alerts!');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }

    const handleTestEmail = () => {
      // Implement email sending functionality here
      if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          const content = '<p>You asked for a prediction market alert when things were about to go very wrong, these are the current values</p>'+Object.entries(marketPositions).map(([question, probability]) => `
              <div>
                  <p>${question}:</p>
                  <p>${(probability * (marketWeights[question] || 0)).toFixed(2)}%</p>
              </div>
          `).join('');
          sendEmailViaWorker(email, content);
      } else {
          alert('Please provide a valid email address');
      }


  };

    var totalWeight = Object.values(marketWeights).reduce((a, b) => parseInt(a) + parseInt(b), 0.01);

    if (totalWeight === 0) {
        return (
            <div className="app">
                <Confetti />
                <h1>I guess you're not worried about AGI, yaay</h1>
            </div>
        );
    }

    var weightedProbability = Object.entries(marketPositions).reduce((total, [question, probability]) => total + (probability * (marketWeights[question] || 0)), 0) / totalWeight;

    return (
        <div className="app">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', height: '100%' }}>
                <h1 style={{ textAlign: 'center', marginBottom: '10px' }}>AGI Fire Alarm 🚨🚨🚨</h1>
                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 'larger' }}>We have indexed a few prediction markets, when the weighted probability of these markets goes above the threshold you set, we will shoot you an email</p>
                <div style={{ width: '40%', marginBottom: '0px' }}>
                    
                    <input type="range" id="threshold" min="0" max="100" value={threshold} onChange={e => handleThresholdChange(parseInt(e.target.value))} />
                    <p htmlFor="threshold" style={{ marginBottom: '0px' }}>Threshold</p>
                </div>
                <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>Current Weighted Probability: {(weightedProbability * 100).toFixed(2)}% <br></br>Send me an alert when it crosses: {threshold.toFixed(2)}%</h4>
               
                <div style={{ width: '40%', marginBottom: '10px' }}>
                    <input type="email" value={email} onChange={e => handleEmailChange(e.target.value)} placeholder="Enter your email" style={{ width: '100%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
                    <button onClick={setAlert}>Send Alert</button>
                    <button onClick={handleTestEmail}>Send a test email and mark us as not spam</button>
                    <button onClick={() => setModalIsOpen(true)}>Add a new market</button>
                </div>
                <Modal 
                    isOpen={modalIsOpen} 
                    onRequestClose={() => setModalIsOpen(false)}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '40%', // Set width to 50% of the view width
                            height: '40%' // Set height to 50% of the view height
                        }
                    }}
                >
                    <h2>Add a new market</h2>
                    <input type="text" value={newMarketUrl} onChange={e => setNewMarketUrl(e.target.value)} placeholder="Enter a manifold.markets url" style={{ width: '100%', marginBottom: '10px' }} />
                    <button onClick={handleAddMarket}>Add</button>
                </Modal>
                <div className="weight-bar" style={{ width: '80%', margin: '10px auto' }}>
                    {Object.entries(marketWeights).map(([question, weight], index) => (
                        <div key={question} className="weight-bar__segment" style={{width: `${(weight / totalWeight) * 100}%`, backgroundColor: colors[index % colors.length]}}></div>
                    ))}
                </div>
            </div>
            
            <div className="market-cards">
                {Object.entries(marketPositions).map(([question, probability], index) => (
                    <div key={question} className="market-card" style={{ borderColor: colors[index % colors.length], borderWidth: '5px', borderStyle: 'solid' }}>
                        <h2 className="question"><a href={marketUrls[question]} target="_blank" rel="noopener noreferrer">{question}</a></h2>
                        <p>Current Probability: {(probability * 100).toFixed(2)}%</p>
                        <input type="range" min="0" max="100" value={marketWeights[question] || 0} onChange={e => handleWeightChange(question, parseInt(e.target.value))} />
                        <p style={{ textAlign: 'center', display: 'block' }}>Weightage</p>
                        <button className="remove-market-btn" onClick={() => handleRemoveMarket(question)}>X</button>
                    </div>
                ))}
            </div>
            <footer style={{ textAlign: 'center', padding: '10px 0', width: '100%', fontSize: '0.8rem', marginTop: '10px' }}>
               <p>Made by <a href='https://twitter.com/raghavtosh'>Raghav</a> and <a href='https://twitter.com/adityaarpitha'>Aditya</a> and mostly via prompting LLMs 🙂</p> 
            </footer>
        </div>
    );
}

export default App;
